html, body {
  height: inherit !important;
  /* font-size: 13px; */
}

/* @media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}  */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.no-border {
  border: 0 !important;
  box-shadow: none !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-v {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.no-padding-h {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.hide-me {
  display: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield !important;
}