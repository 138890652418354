.table-dashboard.ui.table {
    border: none !important;
  }
  
  .table-dashboard.ui.table tr {
    height: 34px;
    cursor: pointer;
  }

.Dashboard .ui.right.rail {
    margin: 0 2rem 0 1rem;
    padding: 0;
}

.Dashboard .ui.rail {
    width: 175px;
}

.Dashboard .rdt_Table .rdt_TableBody {
  overflow-y: auto;
}

.new-appraisal-modal.ui.modal .scrolling.content, .new-appraiser-modal.ui.modal .scrolling.content {
  max-height: calc(50vh);
    overflow: auto;
}

.new-appraisal-modal.ui.modal .scrolling.content.properties {
  max-height: calc(25vh);
}

.ui.dimmer.workaround {
  z-index: 1001 !important;
}

.appraisals-addresses-container > p, .appraisals-units-container > p, .appraisals-value-container > p {
  margin: 0 !important;
}